import request from '../config/request'

export function getKIP (parameter) {
  return request.post('/pembayaran/saldo/kip', parameter)
}

export function getTagihan (parameter) {
  return request.post('/pembayaran/inquiry', parameter)
}

export function getRincian (parameter) {
  return request.post('/pembayaran/rincianbiaya', parameter)
}

export function addTagihan (parameter) {
  return request.post('/pembayaran/addtagihan', parameter)
}

export function payKip (parameter) {
  return request.post('/pembayaran/payment/kip', parameter)
}
