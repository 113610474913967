<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-lg-4">
        <div class="card br-t-white">
          <img class="card-img-top img-fluid" src="/img/img-kip.png" alt="#">
          <div class="card-body">
            <ul class="list-inline">
              <li class="pr-5">Informasi Saldo KIP</li>
            </ul>
            <h3>Rp. {{ $filters.formatRp(saldoKip) }}</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Informasi
            </p>
          </div>
          <div class="card-body padding-t-0">
            <ol>
              <li>Penerima Beasiswa KIP adalah Mahasiswa yang lulus seleksi pendaftaran KIP</li>
              <li>Tagihan UKT dapat dibayar menggunakan saldo KIP</li>
              <li>Saldo KIP di SIAKAD tidak dapat ditarik tunai </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
import Loading from 'vue-loading-overlay'
import { getKIP } from '@/services/keu.service'
import { useStore } from 'vuex'
export default {
  name: 'BeasiswaKip',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userName: users.username,
      profil: {},
      saldoKip: 0,
      isRegister: false,
      isLoading: false,
      fullPage: true,
      errorMsg: ''
    }
  },
  created () {
    this.handleKIP()
  },
  methods: {
    async handleKIP () {
      this.isLoading = true
      getKIP({ nipd: this.userName })
        .then(response => {
          const saldo = response.saldo
          this.saldoKip = saldo
          this.isLoading = false
        }).catch(error => {
          this.errorMsg = (error.response) ? {} : {}
          this.isLoading = false
        })
    }
  }
}
</script>
